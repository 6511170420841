import React, { useState, useEffect } from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import { MenuItem, Select } from "@mui/material";

import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";

import Alert from "react-bootstrap/Alert";
import Cookies from "js-cookie";

const AccountDetail = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");  // Tracks the selected country (Region)
  const [selectedOccupation, setSelectedOccupation] = useState("");  // Tracks the selected Occupation

  const selectCountryHandler = (value) => setSelectedCountry(value);

  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  const countryObj = countries.getNames("en", { select: "official" });
  const countryArr = Object.entries(countryObj).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const [register, setregister] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    location: "",  // Stores the selected Region (Country)
    Occupation: "",  // Stores the selected Occupation
  });

  let registerURL = `https://dipndipapi.mass-fluence.com/api/register?token=${Cookies.get()._auth
    }&user_id=${JSON.parse(Cookies.get()._auth_state).id}`;

  function handle(e) {
    const newData = { ...register };
    newData[e.target.id] = e.target.value;
    setregister(newData);
  }

  function handleLocation(e) {
    const newData = { ...register };
    newData["location"] = e.target.value;
    setregister(newData);
  }

  function handleOccupation(e) {
    const newData = { ...register };
    newData["Occupation"] = e.target.value;
    setregister(newData);
  }

  const [response, setresponse] = useState();

  function submit(e) {
    e.preventDefault();
    Axios.post(registerURL, {
      firstName: register.firstName,
      lastName: register.lastName,
      email: register.email,
      password: register.password,
      location: register.location,  // Send the Region (Country) value
      Occupation: register.Occupation,  // Send the Occupation value
    })
      .then((res) => {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 4000);
      })
      .catch((ERR) => {
        setShow2(true);
        setTimeout(() => {
          setShow2(false);
        }, 4000);
      });
  }

  return (
    <div>
      <form
        onSubmit={(e) => submit(e)}
        id="formContainer4243"
        className="fwrer4235twee"
      >
        <h4 id="titrer343">User Details</h4>

        <div id="inputContainer43">
          <div id="flexingInputs">
            <div>
              <label htmlFor="firstName">First Name</label> <br />
              <input
                onChange={(e) => handle(e)}
                value={register.firstName}
                type="text"
                id="firstName"
                style={{ color: "var(--color-text)" }}
                placeholder="First Name"
              />
            </div>
            <div>
              <label htmlFor="lastName">Last Name</label> <br />
              <input
                onChange={(e) => handle(e)}
                value={register.lastName}
                id="lastName"
                style={{ color: "var(--color-text)" }}
                placeholder="Last Name"
              />
            </div>
          </div>
          <br />
          <label htmlFor="email">Email</label> <br />
          <input
            onChange={(e) => handle(e)}
            value={register.email}
            type="email"
            style={{ color: "var(--color-text)" }}
            id="email"
            placeholder="Email"
          />
          <br />
          <br />
          <label htmlFor="password">Password</label> <br />
          <input
            onChange={(e) => handle(e)}
            value={register.password}
            type="text"
            id="password"
            style={{ color: "var(--color-text)" }}
            placeholder="Password"
          />
        </div>
        <br />

        {/* Region (Location) Selection */}
        <div id="RegionContainer">
          <label className="mb-2" htmlFor="location">
            Region
          </label>

          <Select
            style={{
              width: "100%",
              borderRadius: "10px",
              borderBottom: "1px solid #C1C7CD",
              background: "var(--color-background)",
              border: "none",
              height: "48px",
              padding: "12px 16px",
              marginTop: "6px",
              color: "var(--color-text)",
            }}
            value={selectedCountry}
            onChange={(e) => {
              selectCountryHandler(e.target.value);
              handleLocation(e); // Update location in state
            }}
            placeholder="Region"
          >
            {!!countryArr?.length &&
              countryArr.map(({ label, value }) => (
                <MenuItem
                  style={{
                    background: "var(--color-background)",
                    color: "var(--color-text)",
                  }}
                  key={value}
                  value={value}
                >
                  {label}
                </MenuItem>
              ))}
          </Select>
        </div>
        <br />

        {/* Occupation Selection */}
        <div id="OccupationContainer">
          <label className="mb-2" htmlFor="Occupation">
            Occupation
          </label>

          <Select
            style={{
              width: "100%",
              borderRadius: "10px",
              borderBottom: "1px solid #C1C7CD",
              background: "var(--color-background)",
              border: "none",
              height: "48px",
              padding: "12px 16px",
              marginTop: "6px",
              color: "var(--color-text)",
            }}
            value={selectedOccupation}
            onChange={(e) => {
              setSelectedOccupation(e.target.value);
              handleOccupation(e); // Update occupation in state
            }}
            placeholder="Occupation"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,  // Optional: Set the max height for dropdown
                  width: "100%",   // Ensure the dropdown is the same width as the input
                },
              },
              anchorOrigin: {
                vertical: "bottom",  // Open the menu below the select
                horizontal: "left",  // Align it to the left of the select
              },
              transformOrigin: {
                vertical: "top",     // Position the menu above the anchor
                horizontal: "left",  // Align it to the left of the select
              },
            }}
          >
            <MenuItem
              style={{
                background: "var(--color-background)",
                color: "var(--color-text)",
              }}
              value="Super Admin"
            >
              Super Admin
            </MenuItem>
            <MenuItem
              style={{
                background: "var(--color-background)",
                color: "var(--color-text)",
              }}
              value="Admin"
            >
              Admin
            </MenuItem>
            <MenuItem
              style={{
                background: "var(--color-background)",
                color: "var(--color-text)",
              }}
              value="Subuser"
            >
              Sub-user
            </MenuItem>
          </Select>
        </div>

        <br />
        <div id="AddBtnContainer34">
          <button type="submit">Add</button>
        </div>
      </form>

      {/* Success Alert */}
      <Alert id="mainContainerForalert" show={show} variant="success">
        <div className="alertEditing">
          <div>Successfully Saved.</div>
          <button
            onClick={() => {
              setShow(false);
            }}
            className="btn-close"
          ></button>
        </div>
      </Alert>

      {/* Error Alert */}
      <Alert id="mainContainerForalert" show={show2} variant="danger">
        <div className="alertEditing">
          <div>Failed Saved.</div>
          <button
            onClick={() => {
              setShow2(false);
            }}
            className="btn-close"
          ></button>
        </div>
      </Alert>
    </div>
  );
};

export default AccountDetail;
