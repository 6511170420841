import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";


export const GoToLibraryButton = ({ user }) => {
    return (
        <Link to={`/${user}/Library/${JSON.parse(Cookies.get()._auth_state).id
            }`}>
            <div className="flex items-center gap-2 mt-1 mr-4 max-w-[1200px]">
                <p className="flex w-full justify-end text-lg text-redish font-bold sage"> Go to library</p>
                <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{
                        color: "var(--color-text)",
                        fontSize: "15px",
                        cursor: "pointer",
                    }}
                />
            </div>
        </Link>
    )
}