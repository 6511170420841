import "../pageviws.css";
import React from "react";
import img from "../../../../Assests/images/gfdgdgdg34gwd.svg";
import img1 from "../../../../Assests/images/ellipgdfgfdse-777.svg";
import img2 from "../../../../Assests/images/elldgfdfg34ipse-778.svg";
import QuickChart from "quickchart-js";

export const PagesViews = ({ data }) => {
  const qc = new QuickChart();

  qc.setConfig({
    type: "doughnut",
    data: {
      datasets: [
        {
          data: [
            data?.Management_Training?.num_views || 0,
            data?.Operations_Forms?.num_views || 0,
            data?.Initial_Training?.num_views || 0,
          ],
          backgroundColor: ["#D41F2F", "#F0281F", "#F1B0AD"],
        },
      ],
    },
    options: {
      cutoutPercentage: 70,
      plugins: {
        datalabels: {
          display: false,
        },
      },
      title: {
        display: false,
      },
    },
  });
  qc.setWidth(200).setHeight(200).setBackgroundColor("white");

  return (
    <div className="PagesViewsTemplate page page-5">
      <div className="control-section">
        <div className="d-flex ">
          <div id="chartDetails23">
            <h2>Pages Views</h2>
            <h1 style={{ margin: "10px" }}>{data?.pages_views}</h1>
            <p>Since Last Month</p>

            <div className="mt-5 pt-3">
              <div id="aboutChart">
                <img alt="ERR404" src={img} />
                <span>{data?.Operations_Forms.percentage}% Operations Forms</span>
              </div>

              <div id="aboutChart">
                <img alt="ERR404" src={img1} />
                <span>{data?.Management_Training.percentage}% Management Training</span>
              </div>

              <div id="aboutChart">
                <img alt="ERR404" src={img2} />
                <span>{data?.Initial_Training.percentage}% Initial Training</span>
              </div>
            </div>
          </div>
          <img className="w-[180px] h-[180px]" src={`${qc.getUrl()}`} />
        </div>
      </div>
    </div>
  );
};
export default PagesViews;
