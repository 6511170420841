import React, { useContext, useState } from "react";
import "./rightDash.css";
import MessengerBox from "./messengerBox";
import { Personscontext } from "../../../context/context";
// import { Avatar } from "@mui/material";
import { useUserContext } from "../../../context/userContext.tsx";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RightDash = () => {
  const { userId } = useUserContext();

  const [roomProperty, setroomProperty] = useState({
    RoomId: "",
    personName: "",
    personImage: "",
    personlocation: "",
    personoccupation: "",
    personstatus: "",
    message: "",
  });

  const { ItemsDetails2343 } = useContext(Personscontext);

  const [modalShow22, setModalShow22] = React.useState(false);
  return (
    <div
      style={{
        backgroundColor: "var(--color-frame)",
      }}
      className="h-screen w-[70px]"
    >
      <div id="mainMessegContainerBox">
        <MessengerBox />
      </div>

      {ItemsDetails2343?.length
        ? ItemsDetails2343.map((item) => {
          const isOne = item.user_one.id === userId;

          return (
            <div className="flex flex-col justify-center w-full items-center gap-4 pt-8" key={item.id}>
              <div
                onClick={() => {
                  setModalShow22(true);
                  setroomProperty({
                    RoomId: `${item.id}`,
                    personName: `${isOne ? item.user_two.name : item.user_one.name}`,
                    personImage: `${isOne ? item.user_two.image : item.user_one.image}`,
                    personoccupation: `${isOne ? item.user_two.occupation : item.user_one.occupation}`,
                    personlocation: `${isOne ? item.user_two.location : item.user_one.location}`,
                    personstatus: `${isOne ? item.user_two.status : item.user_one.status}`,
                    personmessages: `${isOne ? item.user_two.status : item.user_one.status}`,
                  });
                }}
                id="person"
              >
                {!item.user_two.image || !item.user_one.image ? (
                  <FontAwesomeIcon icon={faCircleUser} style={{ color: "#798396", fontSize: "50px" }} />
                ) : (
                  <img
                    src={isOne ? item.user_two.image : item.user_one.image}
                    alt="ERR404"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </div>
            </div>
          );
        })
        : null}
      <MessengerBox
        show={modalShow22}
        onHide={() => setModalShow22(false)}
        RoomId={roomProperty.RoomId}
        personName={roomProperty.personName}
        personImage={roomProperty.personImage}
        personoccupation={roomProperty.personoccupation}
        personlocation={roomProperty.personlocation}
        personstatus={roomProperty.personstatus}
        personmesseges={roomProperty.personmesseges}
      />
    </div>
  );
};

export default RightDash;
