import React, { useEffect, useState } from "react";
import RightDash from "../../../utils/Navbar/RightDash/rightDash";
import Topnav from "../../../utils/Navbar/topnav/topnav";
import { Col, Row } from "react-bootstrap";
import axios from "axios";

import img from "../../../Assests/images/icfgdsgsgg34on.svg";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import Cookies from "js-cookie";

const AdminAnnouncemnts = () => {
  const [ItemsDetails2343, setItemsDetails23] = useState([]);

  const [Static, SetStatic] = useState([]);

  const fetchAxios = async () => {
    const res = await axios.get(
      `https://dipndipapi.mass-fluence.com/api/announcemnts?token=${Cookies.get()._auth}&user_id=${JSON.parse(Cookies.get()._auth_state).id
      }`
    );

    setItemsDetails23(res.data.data);
    SetStatic(res.data.data[0]);
  };

  useEffect(() => {
    fetchAxios();
  }, []);

  useEffect(() => {
    SetImg(Static.image);
    SetData(Static.date);
    SetTilte(Static.title);
    SetText(Static.text);
    SetID(0);
    SetIDD(Static.id);
  }, [Static]);

  const [Img, SetImg] = useState();
  const [Data, SetData] = useState();
  const [Title, SetTilte] = useState();
  const [Text, SetText] = useState();
  const [ID, SetID] = useState();
  const [IDD, SetIDD] = useState();

  // model
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Col id="Announcemnts423453" className="d-flex justify-content-between align-items-start mb-5">
      <div style={{ width: "85px" }}></div>

      <div id="containerForAnnonu3434">
        <div id="announcemnts_title">
          <h1>Announcemnts</h1>
          <div>
            <p className="main-color">Check whats happening in dipndip</p>
          </div>
        </div>

        <Row className="mt-2 container d-flex justify-content-between ">
          <Col id="first_half_Announcemnts" className="col-6">
            <Fade left id="cards_info434">
              {ItemsDetails2343?.length >= 1 ? (
                ItemsDetails2343.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        SetImg(item.image);
                        SetData(item.date);
                        SetTilte(item.title);
                        SetText(item.text);
                        SetID(index);
                        SetIDD(item.id);
                      }}
                      id="card_items3234"
                      key={item.id}
                    >
                      <div>
                        <img alt="ERR404" src={item.image} style={{ height: "150px", borderRadius: "20px" }} />
                      </div>

                      <div>
                        <p>{item.date}</p>
                        <h4>{item.title}</h4>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h1 className="d-flex justify-content-center mt-5 text-redish">No Items</h1>
              )}
            </Fade>
          </Col>

          <Col id="second_half323_Announcemnts" className="col-6">
            <Fade right id="dsaf323434f">
              <div id="card_detailes211_title">
                <div>
                  <h1>{Data}</h1>
                </div>
              </div>
              <div id="item_detailes23232">
                <img alt="ERR404" src={Img} style={{ height: "350px", borderRadius: "20px" }} />
                <h1>{Title}</h1>

                <p>{Text}</p>
              </div>
            </Fade>
          </Col>
        </Row>
      </div>

      <div style={{ width: "85px" }}></div>
      <div style={{ position: "fixed", right: "0" }}></div>
    </Col>
  );
};

export default AdminAnnouncemnts;
