import React, { useState, useEffect } from "react";
import "./Home.css";
import { Button, Modal } from "react-bootstrap";
import EditeImgIcon from "../../../Assests/images/home-icohfhfgn-heroicons-solid-pencil-alt.svg";
import { Fade } from "react-reveal";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Cookies from "js-cookie";
import axios from "axios";
import { ModalTitleWithClose } from "../../shared/ModalTitle.tsx";
import { useUserContext } from "../../../context/userContext.tsx";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

export const TextSection = () => {
    const { userRole, userId, userToken } = useUserContext();

    const [quote, setqoute] = useState({
        quote: "",
    });
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const handleShow2 = () => setShow2(true);
    const [quote21, setquote12] = useState({ new_quote: "" });
    const { enqueueSnackbar } = useSnackbar();

    const fetchAxios2 = async () => {
        const res2 = await axios.get(`https://dipndipapi.mass-fluence.com/api/quote?token=${userToken}&user_id=${userId}`);

        setqoute(res2.data.data);
    };
    useEffect(() => {
        fetchAxios2();
    }, []);
    function submit(e) {
        e.preventDefault();
        setIsUpdating(true);
        axios
            .post("https://dipndipapi.mass-fluence.com/api/update-quote", {
                new_quote: quote21.new_quote,
                user_id: `${JSON.parse(Cookies.get("_auth_state") as string).id}`,
                token: Cookies.get()._auth,
            })
            .then((res) => {
                if (res.status === 201) {
                    enqueueSnackbar("Updated Successfully", { variant: "success" });
                    fetchAxios2();
                    setIsUpdating(false);
                }
                handleClose2();
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong please try again", { variant: "error" });
                setIsUpdating(false);
            });
    }
    function handle(e) {
        const newData = { ...quote21 };
        newData[e.target.id] = e.target.value;
        setquote12(newData);
    }

    return (
        <Fade bottom>
            <div className="bg-[#D41F2F] w-[578px] h-[257px] rounded-[20px] flex items-center justify-center relative">
                <p className="text-[48px] w-[90%] font-bold text-white">“{quote?.quote}”</p>
                {userRole === "SuperAdmin" ? (
                    <button onClick={handleShow2} id="EditIconLastContainer443">
                        <img alt="ERR404" src={EditeImgIcon} />
                    </button>
                ) : null}

                <Modal show={show2} onHide={handleClose2}>
                    <Modal.Header>
                        <Modal.Title style={{ color: "var(--color-text)" }}>
                            <ModalTitleWithClose onClose={handleClose2} titleText="Update Text" />
                        </Modal.Title>
                    </Modal.Header>

                    <form className="px-4 pt-4 flex flex-col" onSubmit={(e) => submit(e)}>
                        <label
                            style={{
                                color: "var(--color-text)",
                            }}
                        >
                            Add text
                        </label>{" "}
                        <br></br>
                        <input
                            onChange={(e) => handle(e)}
                            value={quote21.new_quote}
                            type="text"
                            id="new_quote"
                            placeholder="Add text..."
                        />
                        <div>
                            <div className="flex items-center justify-between w-full" id="fsadiu34634bg">
                                <LoadingButton
                                    loading={isUpdating}
                                    color="error"
                                    variant="contained"
                                    className="!rounded-xl !bg-redish !capitalize py-3 px-4 my-2"
                                    type="submit"
                                >
                                    Save Changes
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                </Modal>
            </div>
        </Fade>
    );
};
