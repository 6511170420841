import React, { useEffect } from "react";
import RightDash from "../../../utils/Navbar/RightDash/rightDash.js";
import Topnav from "../../../utils/Navbar/topnav/topnav.js";
import { Col } from "react-bootstrap";
import "./Setting.css";
import AccountDetail from "./accountDetail.js";
import FilesAccessibillity from "./FilesAccessibillity.js";
import Countriesmanagement from "./Countriesmanagement.js";
import WelcomeEmail from "./WelcomeEmail.js";
import { MembersTable } from "./MembersTable.tsx";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Maps } from "./Maps/index.tsx";
import { MapsBranches } from "./MapsBranches/index.tsx";
import { SliderSetting } from "./Slider/BranchesTable.tsx";

const Setting = () => {
  const [value, setValue] = React.useState("account");
  useEffect(() => {
    const storedValue = localStorage.getItem("selectedTab");
    if (storedValue) {
      setValue(storedValue);
    }
  }, []);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    localStorage.setItem("selectedTab", newValue);
  };

  const tabs = [
    { title: "Account Details", value: "account", component: <AccountDetail /> },
    { title: "Files Accessibility", value: "files", component: <FilesAccessibillity /> },
    { title: "Member Accounts", value: "member", component: <MembersTable /> },
    { title: "Countries Management", value: "countries", component: <Countriesmanagement /> },
    { title: "Welcome Email", value: "welcome", component: <WelcomeEmail /> },
    { title: "Map settings", value: "maps", component: <Maps /> },
    { title: "Map branches", value: "branches", component: <MapsBranches /> },
  ];

  return (
    <div className="flex items-center justify-center relative">
      <div id="containerForSetting3434">
        <div id="Setting_title">
          <h1>Settings</h1>
          {/* <div>
            <p className="mt-3">Accounts Details</p>
          </div> */}
        </div>

        <Box sx={{ typography: "body1", my: 6 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "#D9D9D9" }}>
              <TabList
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                  maxWidth: "90%",
                  ".MuiTabs-scrollButtons.Mui-disabled": {
                    opacity: 0.3,
                  },
                }}
                TabIndicatorProps={{ style: { background: "#D41F2F", height: "3px" } }}
                onChange={handleChange}
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    sx={{
                      textTransform: "none",
                    }}
                    label={
                      <p
                        style={{
                          color: "var(--color-text)",
                        }}
                        className="font-bold text-xl !pl-0"
                      >
                        {tab.title}
                      </p>
                    }
                    value={tab.value}
                  />
                ))}
              </TabList>
            </Box>
            {tabs.map((tab) => (
              <TabPanel key={tab.value} sx={{ my: 4 }} value={tab.value}>
                {tab.component}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </div>

      <div style={{ position: "fixed", right: "0", top: 0 }}></div>
    </div>
  );
};

export default Setting;
