import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Home.css";
import 'swiper/css/navigation';

import img1 from "../../../Assests/images/home-page-slider.png";
import { Navigation, Pagination,Autoplay  } from "swiper/modules";
import SlidersAddModal from "./SlidersAddModal";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import SlidersUpdateModal from "./SlidersUpdateModal";
import axios from "axios";
import { useUserContext } from "../../../context/userContext.tsx";
import { useSnackbar } from "notistack";

export const HomePageFeatureSlider = ({ items, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [openAddSlide, setOpenAddSlide] = useState(null);
    const [openEditSlide, setOpenEditSlide] = useState(null);
    const handleOpenAdd = (slide) => setOpenAddSlide(slide);
    const handleOpenEdit = (slide) => setOpenEditSlide(slide);
    const handleCloseAdd = () => {
        setOpenAddSlide(null);
        refetch();
    };
    const handleCloseEdit = () => {
        setOpenEditSlide(null);
        refetch();
    };
    const { userId, userRole } = useUserContext();
    const handelDelete = (id) => {
        axios
            .post("https://dipndipapi.mass-fluence.com/api/delete-slider-homepage", {
                section_id: `${id}`,
                user_id: `${userId}`,
            })
            .then(() => {
                enqueueSnackbar("Image deleted successfully", { variant: "success" });
                refetch();
            });
    };

    return (
        <div className="w-[573px] h-[480px]">
{items.length ? (
    <Swiper
        navigation
        slidesPerView={1}
        autoplay={{
            delay: 5000, // Slide every 5 seconds
            disableOnInteraction: false, // Continue autoplay after interaction
        }}
        style={{
            "--swiper-pagination-bullet-inactive-color": "white",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-active-size": "50px",
            "--swiper-pagination-bullet-size": "8px",
            "--swiper-pagination-bullet-horizontal-gap": "6px",
        }}
        className="features-slider"
        modules={[Navigation, Autoplay]} // Add Autoplay module
    >
        {items?.map((slide, index) => (
            <SwiperSlide
                key={index}
                className="relative group duration-300 rounded-[20px] ease-linear px-2 !min-w-[560px]"
            >
                {userRole === "SuperAdmin" ? (
                    <div className="absolute right-4 top-4 z-50">
                        <Dropdown>
                            <Dropdown.Toggle className="border-0">
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: "30px",
                                    }}
                                    icon={faEllipsisV}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => {
                                        handleOpenAdd(slide);
                                    }}
                                >
                                    Add
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        handleOpenEdit(slide);
                                    }}
                                >
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        handelDelete(slide.id);
                                    }}
                                >
                                    Delete
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ) : null}

                <div className="hover:bg-black/30 hidden group-hover:flex rounded-[20px] px-2 absolute w-[97.5%] h-[96%] duration-300 ease-linear group-hover:opacity-90">
                    <p className="!text-3xl decoration-transparent w-full !text-white text-center absolute bottom-10 font-bold left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        {slide.text}
                    </p>
                </div>
                <img alt="ERR404" src={slide.image} className="feature-image" />
                <SlidersAddModal onClose={handleCloseAdd} open={openAddSlide === slide} re />
                <SlidersUpdateModal onClose={handleCloseEdit} open={openEditSlide === slide} item={slide} />
            </SwiperSlide>
        ))}
    </Swiper>
) : (
    <h1 className="flex items-center justify-center h-full w-full text-redish">No Items</h1>
)}

        </div>
    );
};
