import React from "react";
import { Document, Page, StyleSheet, View } from "@react-pdf/renderer";
import { UsersOnline } from "./Sections/UsersOnline/index.tsx";
import { DeviceLocation } from "./Sections/DeviceLocation/index.tsx";
import { Activity } from "./Sections/Activity/index.tsx";
import { PagesView } from "./Sections/PagesView/index.tsx";
import { AnalyticsHub } from "./Sections/AnalyticsHub/index.tsx";

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        backgroundColor: "#E4E4E4",
    },
    pagesNumber: {
        fontSize: "48px",
        color: "#050505",
        fontWeight: "bold",
    },
    usersStatusOne: {
        backgroundColor: "#F0281F",
        borderRadius: "50%",
        marginRight: "5px",
        width: "12px",
        height: "12px",
    },
    admins: {
        backgroundColor: "#050505",
        borderRadius: "50%",
        marginRight: "5px",
        width: "12px",
        height: "12px",
    },
    subusers: {
        backgroundColor: "#797B7A",
        borderRadius: "50%",
        marginRight: "5px",
        width: "12px",
        height: "12px",
    },
    usersStatusTwo: {
        backgroundColor: "#D41F2F",
        borderRadius: "50%",
        marginRight: "5px",

        width: "12px",
        height: "12px",
    },
    usersStatusThree: {
        backgroundColor: "#F1B0AD",
        borderRadius: "50%",
        marginRight: "5px",

        width: "12px",
        height: "12px",
    },
    chartSubTitle: {
        fontSize: "12px",
        color: "#797B7A",
        fontWeight: "bold",
    },
    card: {
        backgroundColor: "white",
        borderRadius: "20px",
        width: "350px",
        height: "1300px",
        padding: "20px",
    },
    chartCard: {
        backgroundColor: "white",
        borderRadius: "20px",
        width: "350px",
        height: "362px",
        padding: "20px",
    },
    image: {
        width: "50px",
        height: "50px",
        zIndex: -1,
        borderRadius: "50%",
    },
    header: {
        fontSize: "20px",
        fontWeight: 900,
        maxWidth: "60%",
        marginBottom: 5,
        textAlign: "left",
        color: "black",
    },
    child: {
        fontSize: "16px",
        fontWeight: "extrabold",
        textAlign: "left",
        color: "#1B2559",
    },
    subtitle: {
        fontSize: 8,
        flexDirection: "column",
        fontWeight: 400,
        marginLeft: 2,
        marginTop: 2,
        textAlign: "left",
        color: "#A3AED0",
    },
    offlineIcon: {
        backgroundColor: "#D41F2F",
        borderRadius: "50%",
        border: "1px solid black",

        width: "10px",
        height: "10px",
    },
    offline: {
        fontSize: 12,
        fontWeight: 400,
        color: "#D41F2F",
    },
    onlineIcon: {
        backgroundColor: "#53E04E",
        borderRadius: "50%",
        border: "1px solid black",
        width: "10px",
        height: "10px",
    },
    online: {
        fontSize: "12px",
        fontWeight: 400,
        color: "#53E04E",
    },
    item: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        height: "80%",
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        margin: "5px 0px",
        gap: 2,
        width: "250px",
    },
    justRow: {
        flexDirection: "row",
        marginTop: "20px",
    },
    rowEnd: {
        width: "60px",
        justifyContent: "flex-end",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
    },
    twoColumns: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        gap: 15,
    },
    imageWrapper: {
        position: "relative",
    },
    leftOnlineIcon: {
        position: "absolute",
        backgroundColor: "#53E04E",
        borderRadius: "50%",
        border: "1px solid black",
        width: "10px",
        height: "10px",
        zIndex: 999999999999999,
        right: 0,
        bottom: 0,
    },
    leftOfflineIcon: {
        position: "absolute",
        backgroundColor: "#D41F2F",
        borderRadius: "50%",
        border: "1px solid black",
        width: "10px",
        height: "10px",
        zIndex: 999999999999999,
        right: 0,
        bottom: 0,
    },
    locationName: {
        fontSize: "14px",
        fontWeight: "extrabold",
        textAlign: "left",
        color: "#050505",
    },
    locationChild: {
        fontSize: "12px",
        fontWeight: "extrabold",
        textAlign: "left",
        marginLeft: 4,
        marginTop: 4,
        color: "#050505",
    },
    activityTime: {
        fontSize: "14px",
        textAlign: "center",
        marginLeft: 2,
        color: "#797B7A",
    },
    ActivityRowEnd: {
        width: "200px",
        justifyContent: "flex-end",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
    },
});

export default function MyDocument({ analytics }) {
    return (
        <Document>
            <Page dpi={150} size="A4" style={styles.body}>
                <View style={styles.twoColumns}>
                    <AnalyticsHub styles={styles} hub={analytics?.data} />
                    <PagesView styles={styles} views={analytics?.data} />
                    <View style={styles.twoColumns}>
                        <UsersOnline
                            styles={styles}
                            onlineUsers={analytics?.data?.ActiveUsers}
                            offlineUsers={analytics?.data?.OfflineUsers}
                        />
                        <DeviceLocation devices={analytics?.data?.DeviceAndLocation} styles={styles} />
                    </View>
                </View>
            </Page>
            <Page dpi={150} size="A4" style={styles.body} break>
                <View style={styles.twoColumns}>
                    <Activity styles={styles} activity={analytics?.data?.Activity} />
                </View>
            </Page>
        </Document>
    );
}
