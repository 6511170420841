import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import uploadIcon from "../../../Assests/images/setting-super-hfjadmin-icons.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ImageSize } from "../../ImageSize/index.tsx";
import "./CreatAnn.css";

const CreatAnn = (props) => {
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("No selected file");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Create Announcement State
  const [train, setTrain] = useState({
    titleInputAnn: "",
    DesInputAnn: "",
  });

  const addtrainURL = "https://dipndipapi.mass-fluence.com/api/announcemnts";

  const handle = (e) => {
    const newData = { ...train };
    newData[e.target.id] = e.target.value;
    setTrain(newData);
  };

  const [selectedFile, setSelectedFile] = useState();

  const submit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("title", train["titleInputAnn"]);
    formData.append("text", train["DesInputAnn"]);
    formData.append("user_id", `${JSON.parse(Cookies.get("_auth_state")).id}`);
    formData.append("token", Cookies.get()._auth);

    axios
      .post(addtrainURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        enqueueSnackbar("Announcement added Successfully", { variant: "success" });
        props.onHide();
        props.refetch();
        setTrain({
          titleInputAnn: "",
          DesInputAnn: "",
        });
        setImage(null);
        setIsLoading(false); // Set loading state to false
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
        setIsLoading(false); // Set loading state to false
      });
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title style={{ color: "var(--color-text)" }} id="contained-modal-title-vcenter">
          Create Announcements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <div className="loading-overlay">
            <Spinner animation="border" role="status" className="loading-spinner">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <form id="announcementForm" onSubmit={submit}>
          <div onClick={() => document.querySelector(".ann-image-field").click()} id="image-ann-uploader">
            <input
              type="file"
              accept="image/*"
              className="ann-image-field"
              hidden
              onChange={({ target: { files } }) => {
                if (files[0]) {
                  setFileName(files[0].name);
                  setImage(URL.createObjectURL(files[0]));
                  setSelectedFile(files[0]);
                }
              }}
            />
            {image ? (
              <div className="flex flex-col items-center gap-4">
                <img src={image} alt={fileName} width={160} height={160} />
                <p className="text-redish text-xs">
                  *Please note that the recommended image size is width: 568px, height: 358px
                </p>
              </div>
            ) : (
              <ImageSize src={uploadIcon} alt={fileName} sizes="width: 568px, height: 358px" />
            )}
          </div>

          <br />

          <label>Title</label>
          <br />
          <input
            type="text"
            style={{ color: "var(--color-text)" }}
            onChange={handle}
            value={train.titleInputAnn}
            id="titleInputAnn"
            placeholder="Type Title"
          />
          <br />
          <br />

          <label>Description</label>
          <br />
          <textarea
            onChange={handle}
            value={train.DesInputAnn}
            id="DesInputAnn"
            type="text"
            style={{ color: "var(--color-text)" }}
            placeholder="Type Description"
          />
          <br />
          <br />

          <button id="profileBtn434" type="submit" disabled={isLoading}>
            Save Changes
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreatAnn;
