/**
 * Marker cluster map sample
 */
import React, { useState } from "react";
import "./mainMap.css";
import "./newMap.css";
// import img1 from "../../Assests/images/marker.png";
import img1 from "../../Assests/images/new_pin.png";
import flag from "../../Assests/images/Flag_of_Palestine.svg";
import { useUserContext } from "../../context/userContext.tsx";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";

function MarkerCluster({ countries, branchesNumber }) {
  const [showOverlay, setShowOverlay] = useState(true);
  const { userRole } = useUserContext();
  const position = [15.505, 23.09];
  console.log({ countries });

  const dipndipIcon = new Icon({
    iconUrl: img1,
    iconSize: [30, 32], // size of the icon
  });
  const palstineFlag = new Icon({
    iconUrl: flag,
    iconSize: [60, 40], // size of the icon
  });
  return (
    <div onMouseLeave={() => setShowOverlay(true)} onMouseEnter={() => setShowOverlay(false)} className="relative">
      <p className="bg-white absolute z-[9999999999999] bottom-0 rounded-md text-xs px-2">Powered by google maps</p>
      {showOverlay && countries.length ? (
        <div className="w-full hover:opacity-0 duration-600 ease-in-out h-full bg-black/50 rounded-xl z-[99999999999999999] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex items-center justify-center w-full h-full gap-4 font-bold">
            <div className="text-white text-2xl flex items-center justify-center flex-col p-2 rounded-[20px]">
              <p>{countries?.length}</p>
              <p>Countries</p>
            </div>
            <div className="text-white text-2xl flex items-center justify-center flex-col p-2 rounded-[20px]">
              <p>{branchesNumber}</p>
              <p>Branches</p>
            </div>
          </div>
        </div>
      ) : null}
      <div className="w-[570px] !h-[330px] ">
        <MapContainer
          boxZoom={false}
          attributionControl={false}
          minZoom={2.6}
          zoomControl={false}
          maxBounds={[
            [-90, -180],
            [90, 180],
          ]}
          maxZoom={5}
          className="h-[330px] !rounded-xl "
          center={position}
          zoom={2}
          markerZoomAnimation
          scrollWheelZoom
        >
          <FullscreenControl position="topright" />

          <TileLayer noWrap={true} url="https://www.google.us/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" />
          {countries?.map((country) => (
            <Marker icon={dipndipIcon} position={[country.latitude, country.longitude]}>
              <Popup maxHeight={120} maxWidth={290}>
                <div
                  className="flex flex-col gap-2 items-center justify-center w-full cursor-pointer m-0"
                  onClick={() => (window.location.href = `/${userRole}/countries/${country.id}`)}
                >
                  <p className="text-xs m-0">{country.city}</p>
                  <p className="text-xs m-0">{country.Branch === "test" ? "No branches" : country.Branch}</p>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
}
export default MarkerCluster;
